
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import {
  IMaker,
  IMakerCreateRequest,
  IMakerRequest,
  IMakerUpdateRequest
} from "@/types/maker";
import { MakerRequest } from "@/models/maker";

@Component({})
export default class extends Vue {
  //maker
  @Action("maker/adminGet")
  public getMaker!: (maker_id: number) => void;

  @Action("maker/adminCreate")
  public create!: (params: IMakerCreateRequest) => Promise<boolean>;

  @Action("maker/adminUpdate")
  public update!: (data: {
    maker_id: number;
    params: IMakerUpdateRequest;
  }) => Promise<boolean>;

  @Action("maker/adminDelete")
  public deleteMaker!: (maker_id: number) => Promise<boolean>;

  @Action("maker/adminIsUniqueName")
  public isUniqueName!: (params: IMakerRequest) => Promise<boolean>;

  @Action("maker/adminIsUniqueShortName")
  public isUniqueShortName!: (params: IMakerRequest) => Promise<boolean>;

  @Action("maker/adminIsUniqueOrderNoName")
  public isUniqueOrderNoName!: (params: IMakerRequest) => Promise<boolean>;

  @Getter("maker/single")
  public maker!: IMaker;

  @Mutation("maker/clear")
  public clear!: () => void;

  //パラメータ定義
  public params: IMakerRequest = new MakerRequest();

  //変数定義
  public maker_id = 0;
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid = true;
  public lazy = false;
  public unique_validate = false;
  public name_errors: string[] = [];
  public short_name_errors: string[] = [];
  public order_no_name_errors: string[] = [];

  //ルール設定
  public rules = {
    name: [
      (v: string) => !!v || "メーカ名は必須です",
      (v: string) =>
        (v && v.length <= 50) || "メーカ名は50文字以下で入力してください"
    ],
    kana: [
      (v: string) => !!v || "ﾖﾐｶﾞﾅは必須です",
      (v: string) => !v || /^[ｦ-ﾟ]+$/.test(v) || "半角ｶﾀｶﾅで入力してください"
    ],
    short_name: [
      (v: string) => !!v || "短縮表示名は必須です",
      (v: string) =>
        (v && v.length <= 20) || "短縮表示名は20文字以下で入力してください"
    ],
    order_no_name: [
      (v: string) => !!v || "発注番号表示名は必須です",
      (v: string) => /^[a-zA-Z]+$/.test(v) || "半角ローマ字で入力してください",
      (v: string) =>
        (v && v.length == 3) || "発注番号表示名は3文字で入力してください"
    ]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.maker_id = Number(this.$route.params.maker_id);
    this.clear();

    // 編集時
    if (this.maker_id) {
      await this.getMaker(this.maker_id);

      this.setDefault();
    }

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private setDefault() {
    this.params.createFromMaker(this.maker);
    this.unique_validate = true;
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;
    if (this.maker_id) {
      result = await this.update({
        maker_id: this.maker_id,
        params: this.params
      });
    } else {
      result = await this.create(this.params);
    }
    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.submit_dialog = false;
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    if (await this.deleteMaker(this.maker_id)) {
      this.$router.push(`/admin/maker/list`);
    }
  }

  // メーカ名の二重登録チェック
  public async nameValidate() {
    // 登録時
    if (!(await this.isUniqueName(this.params)) && !this.maker.name) {
      this.name_errors = ["既に使用されています"];
      this.unique_validate = false;
      return;
    }

    // 編集時
    if (
      !(await this.isUniqueName(this.params)) &&
      this.maker.name != this.params.name
    ) {
      this.name_errors = ["既に使用されています"];
      this.unique_validate = false;
      return;
    }

    this.name_errors = [];
    this.unique_validate = true;
    return;
  }

  // 短縮表示名の二重登録チェック
  public async shortNameValidate() {
    // 登録時
    if (
      !(await this.isUniqueShortName(this.params)) &&
      !this.maker.short_name
    ) {
      this.short_name_errors = ["既に使用されています"];
      this.unique_validate = false;
      return;
    }

    // 編集時
    if (
      !(await this.isUniqueShortName(this.params)) &&
      this.maker.short_name != this.params.short_name
    ) {
      this.short_name_errors = ["既に使用されています"];
      this.unique_validate = false;
      return;
    }

    this.short_name_errors = [];
    this.unique_validate = true;
    return;
  }

  // 発注番号表示名の二重登録チェック
  public async orderNoNameValidate() {
    // 登録時
    if (
      !(await this.isUniqueOrderNoName(this.params)) &&
      !this.maker.order_no_name
    ) {
      this.order_no_name_errors = ["既に使用されています"];
      this.unique_validate = false;
      return;
    }

    // 編集時
    if (
      !(await this.isUniqueOrderNoName(this.params)) &&
      this.maker.order_no_name != this.params.order_no_name
    ) {
      this.order_no_name_errors = ["既に使用されています"];
      this.unique_validate = false;
      return;
    }

    this.order_no_name_errors = [];
    this.unique_validate = true;
    return;
  }
}
